import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bmx-case-study',
  templateUrl: './bmx-case-study.component.html',
  styleUrls: ['./bmx-case-study.component.scss']
})
export class BMXCaseStudyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
