<div class="page-wrapper" id="page-top">

    <div class="loading-screen"></div>
    <!--end loading-screen-->

    <!--*********************************************************************************************************-->
    <!--************ HERO ***************************************************************************************-->
    <!--*********************************************************************************************************-->
    <header id="hero" style="height: 50px !important;">

        <nav class="navbar navbar-expand-lg navbar-dark fixed-top bg-black">
            <div class="container">
                <a class="navbar-brand" href="#page-top">
                    <img id="logo" src="assets/img/logo-new.png" alt="">
                </a>
                <!--end navbar-brand-->
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup"
                    aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <!--end navbar-toggler-->
                <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
                    <div class="navbar-nav ml-auto">
                        <a class="nav-item nav-link active" href="#page-top">Home <span
                                class="sr-only">(current)</span></a>
                        <a class="nav-item nav-link" href="#what-we-do">What We Do</a>
                        <a class="nav-item nav-link" href="#our-works">Our Portfolio</a>
                        <a class="nav-item nav-link" href="#about-us">About Us</a>
                        <a class="nav-item nav-link" href="#the-team">The Team</a>
                        <a class="nav-item nav-link" href="#contact">Contact</a>
                    </div>
                    <!--end navbar-nav-->
                </div>
                <!--end collapse-->
            </div>
            <!--end container-->
        </nav>
        <!--end navbar-->

    </header>
    <!--end hero-->

    <!--*********************************************************************************************************-->
    <!--************ CONTENT ************************************************************************************-->
    <!--*********************************************************************************************************-->
    <div class="container-fluid" style="padding-top: 20px; background-color: #EFF0F4;">
        <div class="container"></div>
        <section class="pb-0 block" id="about-us">
            <div class="container">
                <div class="block__wrapper">
                    <!-- <div class="block__title">
                                <h2 class="reveal">About Us</h2>
                            </div>-->
                    <!--end block-title-->

                    <div class="row">
                        <div class="col-sm-12 col-md-7">
                            <img class="img-fluid" src="assets/img/pure91/pure1.png" alt="">
                        </div>

                        <!--end col-md-7-->
                        <div class="col-md-5 pt">
                            <h1>PURE91.COM</h1>
                            <br>
                            <h4>Starting afresh in the third decade of this century, Disco & Pretzel is a creative
                                studio starting out from Bombay, India. That is what we are here for; to redefine
                                the
                                science of content. To begin a trend, one needs to keep experimenting with their
                                work
                                and that is the core principle of Disco & Pretzel - to create challenges for
                                ourselves.</h4> <br>
                            <h4> that is the core principle of Disco & Pretzel - to create challenges for
                                ourselves </h4>

                            <!--   <a href="#contact" class="btn btn-default mb-4">Contact Us</a>-->
                        </div>
                        <!--end col-md-5-->
                    </div>
                    <!--end row-->
                </div>
                <!--end block__wrapper-->
            </div>
            <!--end container-->
        </section>
    </div>

    <div class="container-fluid" style="padding-top: 80px; background-color: #151A40;">
        <div class="container"></div>

        <div class="container">

            <!-- <div class="block__title">
                                <h2 class="reveal">About Us</h2>
                            </div>-->
            <!--end block-title-->
            <h3 style="color: #EFF0F4;">PROJECT DESCRIPTION</h3>

            <hr>
            <div class="row">
                <div class="col-md-7">

                    <p style="color: #EFF0F4; font-size: 20px">
                        Starting afresh in the third decade of this century, Disco & Pretzel is a creative
                        studio starting out from Bombay, India. That is what we are here for; to redefine
                        the
                        science of content. To begin a trend, one needs to keep experimenting with their
                        work
                        and that is the core principle of Disco & Pretzel - to create challenges for
                        ourselves.Starting afresh in the third decade of this century, Disco & Pretzel is a creative
                        studio starting out from Bombay, India. That is what we are here for; to redefine
                        the
                        science of content. To begin a trend, one needs to keep experimenting with their
                        work
                        and that is the core principle of Disco & Pretzel - to create challenges for
                        ourselves.
                    </p><br>
                    <p style="color: #EFF0F4; font-size: 20px">that is the core principle of Disco & Pretzel - to create
                        challenges for
                        ourselves.</p>
                    <!--   <a href="#contact" class="btn btn-default mb-4">Contact Us</a>-->
                </div>
                <!--end col-md-7-->
                <div class="col-sm-12 col-md-5">

                    <h2
                        style="color: #EFF0F4; text-align:right; padding-top: 80px; padding-left: 100px; font-size: 60px; margin-bottom: 0px;">
                        PURE 91</h2>
                    <h2
                        style="color: #EFF0F4; text-align:right;  padding-left: 100px; font-size: 60px; margin-bottom: 0px;">
                        CONTENT </h2>
                    <h2
                        style="color: #EFF0F4; text-align:right;  padding-left: 100px; font-size: 60px; margin-bottom: 0px;">
                        STRATEGY </h2>


                </div>
                <!--end col-md-5-->
            </div>
            <!--end row-->

            <!--end block__wrapper-->
        </div>
        <!--end container-->
        <br><br>
    </div>

    <div class="container">
        <h3 style="color: #151A40; padding-top: 120px;  margin-bottom: 0px; font-size: 20px;">
            PHOTOSHOOT</h3>
        <hr class="solid1">
        <div class="row">
            <div class="col-md-6">
                <img class="width-100" src="assets/img/pure91/pure2.png" alt="">
            </div>
            <div class="col-md-6">
                <img class="width-100" src="assets/img/pure91/pure3.png" alt="">
            </div>

            <div class="col-md-6 pdt">
                <img class="width-100" src="assets/img/pure91/pure4.png" alt="">
            </div>
            <div class="col-md-6 pdt">
                <img class="width-100" src="assets/img/pure91/pure5.png" alt="">
            </div>
        </div>
        <h3 style="color: #151A40; padding-top: 120px;  margin-bottom: 0px; font-size: 20px;">
            SOCIAL  MEDIA CREATIVE (Sample) </h3>
        <hr class="solid1">
        <div class="row">
            <div class="col-md-6">
                <img class="width-100" src="assets/img/pure91/pure6.png" alt="">
            </div>
            <div class="col-md-6">
                <img class="width-100" src="assets/img/pure91/pure7.png" alt="">
            </div>

        </div>

        <div class="row">
            <div class="col-md-6">
                <h3 style="color: #151A40; padding-top: 120px;  margin-bottom: 0px; font-size: 20px;">
                    OFFLINE MEDIA DESIGN</h3>
                <hr class="solid">
                <img class="width-100" src="assets/img/pure91/pure8.png" alt="">
            </div>
        </div>
    </div>
    <br>




    <div id="content">

        <!--WHAT WE DO ******************************************************************************************-->

        <!--  <section class="pb-0 block" id="what-we-do">
            <div class="container-fluid" style="padding: 0px;">
                <div class="block__title">-->
        <!-- <h2 class="text-center">PURE91.COM</h2> -->
        <!-- </div>-->
        <!--end block-title-->

        <!-- <div class="container-fluid">
                    <div class="row pt-1">

                        <div class="col-12" style="padding: 0px;">
                            <img src="/assets/Pure 91 Case Study-01.png" class="img-fluid">
                        </div>
                    </div>
                </div>
            </div>-->
        <!--end container-->
        <!--</section>-->
        <!--end #what-we-do.block-->


        <!--*********************************************************************************************************-->
        <!--************ FOOTER *************************************************************************************-->
        <!--*********************************************************************************************************-->
        <footer id="footer" class="skew-up-right">

            <div class="container">
                <span>(C) Copyright 2020 | Powered by Kenmark ITan Solutions</span>
            </div>
            <!--end container-->

            <div class="background-wrapper" data-background-color="#151a40">
                <div class="background background--particles"></div>
            </div>
            <!--end background-->

        </footer>
        <!--end footer-->
    </div>
    <!--end page-->