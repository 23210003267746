<div class="page-wrapper" id="page-top">

    <div class="loading-screen"></div>
    <!--end loading-screen-->

    <!--*********************************************************************************************************-->
    <!--************ HERO ***************************************************************************************-->
    <!--*********************************************************************************************************-->
    <header id="hero" style="height: 50px !important;">

        <nav class="navbar navbar-expand-lg navbar-dark fixed-top bg-black">
            <div class="container">
                <a class="navbar-brand" href="#page-top">
                    <img id="logo" src="assets/img/logo-new.png" alt="">
                </a>
                <!--end navbar-brand-->
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup"
                    aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <!--end navbar-toggler-->
                <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
                    <div class="navbar-nav ml-auto">
                        <a class="nav-item nav-link active" href="#page-top">Home <span
                                class="sr-only">(current)</span></a>
                        <a class="nav-item nav-link" href="#what-we-do">What We Do</a>
                        <a class="nav-item nav-link" href="#our-works">Our Portfolio</a>
                        <a class="nav-item nav-link" href="#about-us">About Us</a>
                        <a class="nav-item nav-link" href="#the-team">The Team</a>
                        <a class="nav-item nav-link" href="#contact">Contact</a>
                    </div>
                    <!--end navbar-nav-->
                </div>
                <!--end collapse-->
            </div>
            <!--end container-->
        </nav>
        <!--end navbar-->

    </header>
    <!--end hero-->

    <!--*********************************************************************************************************-->
    <!--************ CONTENT ************************************************************************************-->
    <!--*********************************************************************************************************-->


    <!--WHAT WE DO ******************************************************************************************-->


    <div class="container-fluid" style="padding-top: 20px; background-color: #EFF0F4;">
        <div class="container"></div>
        <section class="pb-0 block" id="about-us">
            <div class="container">
                <div class="block__wrapper">
                    <!-- <div class="block__title">
                                <h2 class="reveal">About Us</h2>
                            </div>-->
                    <!--end block-title-->

                    <div class="row">
                        <div class="col-sm-12 col-md-7">
                            <img class="img-fluid" src="assets/img/whisk/whisk1.png" alt="">
                        </div>

                        <!--end col-md-7-->
                        <div class="col-md-5">
                            <h1>THE<br> WHISKED<br> PATH </h1>
                            <br>
                            <h4>Starting afresh in the third decade of this century, Disco & Pretzel is a creative
                                studio starting out from Bombay, India. That is what we are here for; to redefine
                                the
                                science of content. To begin a trend, one needs to keep experimenting with their
                                work
                                and that is the core principle of Disco & Pretzel - to create challenges for
                                ourselves.</h4> <br>
                            <h4> that is the core principle of Disco & Pretzel - to create challenges for
                                ourselves </h4>

                            <!--   <a href="#contact" class="btn btn-default mb-4">Contact Us</a>-->
                        </div>
                        <!--end col-md-5-->
                    </div>
                    <!--end row-->
                </div>
                <!--end block__wrapper-->
            </div>
            <!--end container-->
        </section>
    </div>

    <div class="container-fluid" style="padding-top: 80px; background-color: #151A40;">
        <div class="container"></div>

        <div class="container">

            <!-- <div class="block__title">
                                <h2 class="reveal">About Us</h2>
                            </div>-->
            <!--end block-title-->
            <h3 style="color: #EFF0F4;">PROJECT DESCRIPTION</h3>

            <hr>
            <div class="row">
                <div class="col-md-7">

                    <p style="color: #EFF0F4; font-size: 20px">
                        Starting afresh in the third decade of this century, Disco & Pretzel is a creative
                        studio starting out from Bombay, India. That is what we are here for; to redefine
                        the
                        science of content. To begin a trend, one needs to keep experimenting with their
                        work
                        and that is the core principle of Disco & Pretzel - to create challenges for
                        ourselves.Starting afresh in the third decade of this century, Disco & Pretzel is a creative
                        studio starting out from Bombay, India. That is what we are here for; to redefine
                        the
                        science of content. To begin a trend, one needs to keep experimenting with their
                        work
                        and that is the core principle of Disco & Pretzel - to create challenges for
                        ourselves.
                    </p><br>
                    <p style="color: #EFF0F4; font-size: 20px">that is the core principle of Disco & Pretzel - to create
                        challenges for
                        ourselves.</p>
                    <!--   <a href="#contact" class="btn btn-default mb-4">Contact Us</a>-->
                </div>
                <!--end col-md-7-->
                <div class="col-sm-12 col-md-5">
                   
                    <h2
                        style="color: #EFF0F4; padding-top: 80px; padding-left: 100px; font-size: 35px; margin-bottom: 0px;">
                        THE WHISKED PATH</h2>
                    <h2 style="color: #EFF0F4; padding-left: 250px; font-size: 35px;">BRANDING</h2>

                </div>
                <!--end col-md-5-->
            </div>
            <!--end row-->

            <!--end block__wrapper-->
        </div>
        <!--end container-->
        <br><br>
    </div>


    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <h3 style="color: #151A40; padding-top: 120px;  margin-bottom: 0px; font-size: 20px;">
                    LOGOTYPE(DARK)</h3>
                <hr class="solid">
                <img class="width-100" src="assets/img/whisk/whisk2.png" alt="">

            </div>
            <div class="col-md-6">
                <h3 style="color: #151A40; padding-top: 120px;  margin-bottom: 0px; font-size:20px;">
                    LOGOTYPE(LIGHT)</h3>
                <hr class="solid">
                <img class="width-100" src="assets/img/whisk/whisk3.png" alt="">


            </div>
        </div>


        <div class="row">
            <div class="col-md-6">
                <h3 style="color: #151A40; padding-top: 120px;  margin-bottom: 0px; font-size: 20px;">
                    ILLUSTRATION(LIGHT)</h3>
                <hr class="solid">
                <img class="width-100" src="assets/img/whisk/whisk4.png" alt="">

            </div>
            <div class="col-md-6">
                <h3 style="color: #151A40; padding-top: 120px;  margin-bottom: 0px; font-size:20px;">
                    ILLUSTRATION(DARK)</h3>
                <hr class="solid">
                <img class="width-100" src="assets/img/whisk/whisk5.png" alt="">


            </div>
        </div>

        <div class="row">
            <div class="col-md-6">
                <h3 style="color: #151A40; padding-top: 120px;  margin-bottom: 0px; font-size: 20px;">
                    TYPEFACE</h3>
                <hr class="solid">
                <img class="width-100" src="assets/img/whisk/whisk6.png" alt="">

            </div>
            <div class="col-md-6">
                <h3 style="color: #151A40; padding-top: 120px;  margin-bottom: 0px; font-size:20px;">
                    TYPEFACE(Example)</h3>
                <hr class="solid">
                <img class="width-100" style="padding-top: 120px" src="assets/img/whisk/whisk7.png" alt="">


            </div>
        </div>
        <h3 style="color: #151A40; padding-top: 120px;  margin-bottom: 0px; font-size: 20px;">
            COLOUR PALETTE</h3>
        <hr class="solid1">
        <div class="row">
            <div class="col-md-3">
                <img class="width-100" src="assets/img/whisk/whisk8.png" alt="">
            </div>
            <div class="col-md-3">
                <img class="width-100" src="assets/img/whisk/whisk9.png" alt="">
            </div>
            <div class="col-md-3">
                <img class="width-100" src="assets/img/whisk/whisk10.png" alt="">
            </div>
            <div class="col-md-3">
                <img class="width-100" src="assets/img/whisk/whisk11.png" alt="">
            </div>
        </div>

        <div class="row">
            <div class="col-md-6">
                <h3 style="color: #151A40; padding-top: 120px;  margin-bottom: 0px; font-size: 20px;">
                    MERCHNDISE(Sample)</h3>
                <hr class="solid">
                <img class="width-100" src="assets/img/whisk/whisk12.png" alt="">
            </div>
        </div>

    </div>
    <br><br>
</div>




<!--     <section class="pb-0 block" id="about-us">
                    <div class="container">
                        <div class="block__wrapper">
                           <div class="block__title">
                                <h2 class="reveal">About Us</h2>
                            </div>
                   
                          
                            <div class="row">
                                <div class="col-sm-12 col-md-7">
                                    <img class="width-100"
                                          src="assets/img/whisk/whisk1.png" alt="">
                                </div>
                               
                             
                                <div class="col-md-5"> 
        
                                    <h1 class="reveal" >THE</h1><br>
                                    <h1 class="reveal" >WHISKED</h1>
                                    <h1 class="reveal" >PATH</h1>

                                     <a href="#contact" class="btn btn-default mb-4">Contact Us</a>
                                </div>
                             
                            </div>
                           
                        </div>
                        
                    </div>
                </section> -->

<!--  <div class="container-fluid">
                <div class="row pt-1">

                    <div class="col-12" style="padding: 0px;">
                        <img src="/assets/img/TWP Case Study-01.png" class="img-fluid">

                    </div>
                </div>
            </div>-->

<!--end container-->

<!--end #what-we-do.block-->


<!--*********************************************************************************************************-->
<!--************ FOOTER *************************************************************************************-->
<!--*********************************************************************************************************-->
<!--  <div class="container-fluid" style="background-color: #EFF0F4 ;">
        <h1>hello</h1>-->


<footer id="footer" class="skew-up-right">

    <div class="container">
        <span>(C) Copyright 2020 | Powered by Kenmark ITan Solutions</span>
    </div>
    <!--end container-->

    <div class="background-wrapper" data-background-color="#151a40">
        <div class="background background--particles"></div>
    </div>
    <!--end background-->

</footer>
<!--end footer-->

<!--end page-->