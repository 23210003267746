import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, Validators, FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ConstantsService } from '../service/constants.service';
import { apiservice } from '../service/apiservice.service';
import SwiperCore, { A11y, Autoplay, Navigation, Pagination, Scrollbar, SwiperOptions } from 'swiper/core';
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y,Autoplay]);
declare var $: any;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  title = 'Ampersandco';
  name: any;
  addFrom: any;
  email: any;
  message: any;
  divText: string;
  win: Window;
  public config: SwiperOptions = {
    breakpoints: {
      // when window width is >= 320px
      320: {
        slidesPerView:2,
        spaceBetween: 20
      },
      // when window width is >= 480px
      480: {
        slidesPerView: 2,
        spaceBetween: 30
      },
      // when window width is >= 640px
      640: {
        slidesPerView: 2,
        spaceBetween: 40
      },
      // when window width is >= 640px
      767: {
        slidesPerView: 3,
        spaceBetween: 0
      },
      // when window width is >= 1024px
      1024: {
        slidesPerView: 4,
        spaceBetween: 0
      }
    }
  };

  public config1: SwiperOptions = {
    
    breakpoints: {
      // when window width is >= 320px
      320: {
        slidesPerView: 2,
        spaceBetween: 20
      },
      // when window width is >= 480px
      480: {
        slidesPerView: 2,
        spaceBetween: 30
      },
      // when window width is >= 640px
      640: {
        slidesPerView: 3,
        spaceBetween: 40
      },
      // when window width is >= 640px
      767: {
        slidesPerView: 3,
        spaceBetween: 0
      },
      // when window width is >= 1024px
      1024: {
        slidesPerView: 3,
        spaceBetween: 0
      }
    }
  };
  constructor(
    public constantService: ConstantsService,
    public FormBuilder: FormBuilder,
    public api: apiservice,
    public router: Router,
    private toast: ToastrService
  ) {
    this.addFrom = this.FormBuilder.group({
      Name: new FormControl('', [Validators.required]),
      Email: new FormControl('', Validators.compose([Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")])),
      Message: new FormControl('', [Validators.required]),
    })
  }

  ngOnInit() {
  }

  addContact() {
    this.name = $('#Name').val();
    this.email = $('#Email').val();
    this.message = $('#Message').val();
    var data = { "name": this.name, "email": this.email, "message": this.message }
    console.log(data);
    this.api.postRequest(this.constantService.CONTACT, data).then(res => {
      console.log(res);
      this.toast.success(res['message']);
    }).catch(err => {
      console.log(err);
      this.toast.error(err['message']);
    })
  }

  open() {
    window.open('/the-whisked-path', 'blank');
  }
  open1() {
    window.open('/pure91', 'blank');
  }
  open2() {
    window.open('/lacurrygenie', 'blank');
  }
  open3() {
    window.open('/sabjiwalaa', 'blank');
  }
  open4() {
    window.open('/bombay-mixers', 'blank');
  }

  onSwiper(swiper) {
    console.log(swiper);
  }
  onSlideChange() {
    console.log('slide change');
  }
}

