import { Component, OnInit } from '@angular/core';
import { ConstantsService } from './service/constants.service';
import { apiservice } from './service/apiservice.service';
import { Router } from '@angular/router';
import { FormControl, Validators, FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Ampersandco';

  onActive(){
    window.scroll(0, 0);
  }
  name: any;
  addFrom: any;
  email: any;
  
  message: any;
  divText: string;
  win: Window;

  constructor(
    public constantService: ConstantsService,
    public FormBuilder: FormBuilder,
    public api: apiservice,
    public router: Router,
    private toast: ToastrService
  ) {
    
    this.addFrom = this.FormBuilder.group({
      Name: new FormControl('', [Validators.required]),
      Email: new FormControl('', Validators.compose([Validators.email, Validators.required])),
      Message: new FormControl('', [Validators.required]),
    })
  }

  ngOnInit() {
  }

  addContact() {
    this.name = $('#Name').val();
    this.email = $('#Email').val();
    this.message = $('#Message').val();
    var data = { "name": this.name, "email": this.email, "message": this.message }
    console.log(data);
    this.api.postRequest(this.constantService.CONTACT, data).then(res => {
      console.log(res);
      this.toast.success(res['message']);
    }).catch(err => {
      console.log(err);
      this.toast.error(err['message']);
    })
  }



}