import { Injectable, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { SidStorageService } from './sidstorage.service';


@Injectable({
  providedIn: 'root'
})

export class apiservice {
  public tab: any = 'tabhome';
  public userdetails: any;
  public token: any;
  public userRoleidget: any;

  // isLoader = true;

  httpotpions: { headers: HttpHeaders; };

  constructor(public http: HttpClient,
    public router: Router,
    public storageService: SidStorageService,

  ) {
    this.httpotpions = {
      headers: new HttpHeaders({
        "Accept": "application/json",
        "Content-Type":"application/json"
      })
    };
  }


  showHead: boolean = false;

  domain = 'https://discoandpretzel.test-central.kenmarkserver.com/';
  // domain = 'https://test.discoandpretzel.com/';

  // get request start
  getRequest(api, data) {
    return new Promise((resolve, reject) => {
      this.http.get<any>(this.domain + api + data, this.httpotpions).subscribe(res => {
        //if condition data validation
        resolve(res);
      }, error => {
        reject(error);
      })
      // });
    }).catch(err => {
      //  console.log(err);
    });
  }
  // get request end


  // post request start
  postRequest(api, data) {
    return new Promise((resolve, reject) => {
      //console.log(dbres);
      this.http.post<any>(this.domain + api, data,this.httpotpions).subscribe(res => {
        //if condition data validation
        resolve(res);
      }, error => {
        reject(error);
      })
    });
  }
  // post request end 


  // put request start
  putRequest(api, data) {
    return new Promise((resolve, reject) => {
      this.http.put<any>(this.domain + api, data).subscribe(res => {
        //if condition data validation
        resolve(res);
      }, error => {
        reject(error);
      })
    });
  }
  // put request end 


  // patch request start
  patchRequest(api, data) {
    return new Promise((resolve, reject) => {
      this.http.patch<any>(this.domain + api, data).subscribe(res => {
        //if condition data validation
        resolve(res);
      }, error => {
        reject(error);
      })

    });
  }
  // patch request end 


  // delete request start
  deleteRequest(api, data) {
    return new Promise((resolve, reject) => {
      this.http.get<any>(this.domain + api + data).subscribe(res => {
        //if condition data validation
        resolve(res);
      }, error => {
        reject(error);
      })
    });
  }
  // delete request end




}





