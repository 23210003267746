import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ImageComponent } from './image/image.component';
import { HomeComponent } from './home/home.component';
import { WhiskComponent } from './whisk/whisk.component';
import { Pure91CaseStudyComponent } from './pure91-case-study/pure91-case-study.component';
import { LacurryCaseStudyComponent } from './lacurry-case-study/lacurry-case-study.component';
import { SabjiwalaaCaseStudyComponent } from './sabjiwalaa-case-study/sabjiwalaa-case-study.component';
import { BMXCaseStudyComponent } from './bmx-case-study/bmx-case-study.component';


const routes: Routes = [
  { path: '', component: HomeComponent},
  { path: 'then', component: ImageComponent},
  { path: 'the-whisked-path', component: WhiskComponent},
  { path: 'pure91',component: Pure91CaseStudyComponent},
  { path: 'lacurrygenie',component: LacurryCaseStudyComponent},
  { path: 'sabjiwalaa' , component: SabjiwalaaCaseStudyComponent},
  { path: 'bombay-mixers', component: BMXCaseStudyComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
