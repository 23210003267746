import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-whisk',
  templateUrl: './whisk.component.html',
  styleUrls: ['./whisk.component.scss']
})
export class WhiskComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
