import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { SwiperModule } from 'swiper/angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { ReactiveFormsModule } from '@angular/forms';
import { ImageComponent } from './image/image.component';
import { HomeComponent } from './home/home.component';
import { RouterModule } from '@angular/router';
import { WhiskComponent } from './whisk/whisk.component';
import { Pure91CaseStudyComponent } from './pure91-case-study/pure91-case-study.component';
import { LacurryCaseStudyComponent } from './lacurry-case-study/lacurry-case-study.component';
import { SabjiwalaaCaseStudyComponent } from './sabjiwalaa-case-study/sabjiwalaa-case-study.component';
import { BMXCaseStudyComponent } from './bmx-case-study/bmx-case-study.component';

@NgModule({
  declarations: [
    AppComponent,
    ImageComponent,
    HomeComponent,
    WhiskComponent,
    Pure91CaseStudyComponent,
    LacurryCaseStudyComponent,
    SabjiwalaaCaseStudyComponent,
    BMXCaseStudyComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    SwiperModule,
    ToastrModule.forRoot()
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
