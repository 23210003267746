<div class="page-wrapper font" id="page-top">

    <div class="loading-screen "></div>
    <!--end loading-screen-->

    <!--*********************************************************************************************************-->
    <!--************ HERO ***************************************************************************************-->
    <!--*********************************************************************************************************-->
    <header id="hero">

        <nav class="navbar navbar-expand-lg navbar-dark fixed-top">
            <div class="container">
                <a class="navbar-brand " href="#home">
                    <img class="m-0" id="logo" src="assets/img/logo-new.png" alt="">
                </a>
                <!--end navbar-brand-->
                <button class="navbar-toggler " type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup"
                    aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <!--end navbar-toggler-->
                <div class="collapse navbar-collapse navbar-dark" id="navbarNavAltMarkup">
                    <div class="navbar-nav ml-auto">
                        <a class="nav-item nav-link active" href="#home">Home <span class="sr-only">(current)</span></a>
                        <a class="nav-item nav-link" href="#what-we-do">What We Do</a>
                        <a class="nav-item nav-link" href="#the-work">The Work</a>
                        <a class="nav-item nav-link" href="#about-us">About Us</a>
                        <a class="nav-item nav-link" href="#the-team">The Team</a>
                        <a class="nav-item nav-link" href="#contact">Contact</a>
                    </div>
                    <!--end navbar-nav-->
                </div>
                <!--end collapse-->
            </div>
            <!--end container-->
        </nav>
        <!--end navbar-->

        <div class="container" id="home" style="padding-top: 0px;">
            <div class="row ">
                <div class="hero__outer-wrapper">
                    <div class="hero__inner-wrapper align-middle">
                        <h1>
                            <span class="hero__title">We </span>
                            <a class="hero__title text-carousel" href="#home">Create, Design, Inspire</a>
                            <br>
                            <!-- <span class="hero__title">Studio</span> -->
                        </h1>
                        <!--end h1-->
                        <!--<a href="#what-we-do" class="hero__link align-items-center">
                            <figure class="mb-0 align-middle"></figure>
                            Check It
                        </a>
                        <!--end hero__link-->
                    </div>
                    <!--end container-->
                </div>
                <!--end hero__inner-wrapper-->
            </div>
            <!--end row-->
        </div>
        <!--end hero__outer-wrapper-->

        <div class="hero__background skew-down-left d-none d-md-flex ">
            <!--<div class="background-wrapper skew-down-left" data-background-color="#000000" data-parallax="scroll" data-parallax-speed="3" >-->
            <!--<div class="background&#45;&#45;image opacity-30 background&#45;&#45;repeat-repeat parallax-element">-->
            <!--<img src="assets/img/bg-01.jpg" alt="">-->
            <!--</div>-->
            <!--</div>-->
            <div class="background-wrapper" data-parallax="scroll" data-parallax-speed="3"
                data-background-color="#151a40">
                <div class="background opacity-40">
                    <div class="owl-carousel hero__slider parallax-element" data-owl-items="1" data-owl-autoplay="1"
                        data-owl-dots="0" data-owl-nav="1" data-owl-loop="1" data-owl-fadeout="1">
                        <div class="slide img-into-bg">
                            <img src="/assets/img/nbanner1.jpeg" alt="">
                        </div>
                        <div class="slide img-into-bg">
                            <img src="/assets/img/nbanner2.jpeg" alt="">
                        </div>
                        <div class="slide img-into-bg">
                            <img src="/assets/img/nbanner3.jpeg" alt="">
                        </div>
                    </div>
                </div>
            </div>
            <!--end background-->
        </div>
        <div class="hero__background  d-flex d-md-none">
            <!--<div class="background-wrapper skew-down-left" data-background-color="#000000" data-parallax="scroll" data-parallax-speed="3" >-->
            <!--<div class="background&#45;&#45;image opacity-30 background&#45;&#45;repeat-repeat parallax-element">-->
            <!--<img src="assets/img/bg-01.jpg" alt="">-->
            <!--</div>-->
            <!--</div>-->
            <div class="background-wrapper" data-parallax="scroll" data-parallax-speed="3"
                data-background-color="#151a40">
                <div class="background opacity-40">
                    <div class="owl-carousel hero__slider parallax-element" data-owl-items="1" data-owl-autoplay="1"
                        data-owl-dots="0" data-owl-nav="1" data-owl-loop="1" data-owl-fadeout="1">
                        <div class="slide img-into-bg">
                            <img src="/assets/img/wetransfer-a133ae/banner1.jpeg" alt="">
                        </div>
                        <div class="slide img-into-bg">
                            <img src="/assets/img/wetransfer-a133ae/Untitled-2.jpeg" alt="">
                        </div>
                        <div class="slide img-into-bg">
                            <img src="/assets/img/wetransfer-a133ae/Untitled-3.jpeg" alt="">
                        </div>
                    </div>
                </div>
            </div>
            <!--end background-->
        </div>
    </header>
    <!--end hero-->

    <!--*********************************************************************************************************-->
    <!--************ CONTENT ************************************************************************************-->
    <!--*********************************************************************************************************-->
    <div id="content">

        <!--WHAT WE DO ******************************************************************************************-->

        <section class="" id="what-we-do">
            <div class="container d-none d-md-none d-lg-block" style="padding-top: 140px;">
                <!--  <div class="block__title">
                    <h2 class="reveal">What We Do</h2>
                </div>-->
                <!--end block-title-->
                <h2 class="reveal" style="margin-bottom: 60px;">What We Do</h2>
                <swiper [config]="config1" [navigation]="true" [watchSlidesVisibility]="false" [slidesPerGroup]="3"
                    [watchSlidesProgress]="true" [pagination]="{clickable: true}" [slidesPerView]="3"
                    (swiper)="onSwiper($event)" (slideChange)="onSlideChange()"
                    [autoplay]="{ delay:5000,disableOnInteraction: false}">


                    <ng-template swiperSlide>
                        <div class="col-12 justify-content-center">
                            <div class="box">
                                <div class="box__wrapper" style="margin-right: 30px; margin-left: 30px;">
                                    <div class="box__header">
                                        <div class="box__image">
                                            <img src="assets/img/strategy.png" class="" alt="">
                                        </div>
                                        <!--end box__image-->
                                        <h4 class="reveal" style="
                                            margin-top: 0px;"> Content Strategy</h4>
                                    </div>
                                    <!--end box__header-->
                                    <div class="box__content">
                                        <p>The planning, creation, and publication of the content plan for your brand
                                        </p>
                                    </div>
                                    <!--end box__content-->
                                </div>
                                <!--end box__wrapper-->
                            </div>
                            <!--end box-feature-->
                        </div>
                    </ng-template>
                    <ng-template swiperSlide>
                        <div class="col-12">
                            <div class="box">
                                <div class="box__wrapper" style="margin-right: 30px; margin-left: 30px;">
                                    <div class="box__header">
                                        <div class="box__image">
                                            <img src="assets/img/Content writing.png" class="" alt="">
                                        </div>
                                        <!--end box__image-->
                                        <h4 class="reveal" style="
                                            margin-top: 0px;">Content Writing</h4>
                                    </div>
                                    <!--end box__header-->
                                    <div class="box__content">
                                        <p>The planning, writing, and editing for all types of content - websites,
                                            blogs,
                                            articles, script writing, and the rest</p>
                                    </div>
                                    <!--end box__content-->
                                </div>
                                <!--end box__wrapper-->
                            </div>
                            <!--end box-feature-->
                        </div>
                    </ng-template>
                    <ng-template swiperSlide>
                        <div class="col-12">
                            <div class="box">
                                <div class="box__wrapper" style="margin-right: 30px; margin-left: 30px;">
                                    <div class="box__header">
                                        <div class="box__image">
                                            <img src="assets/img/social1.png" class="" alt="">
                                        </div>
                                        <!--end box__image-->
                                        <h4 class="reveal" style="
                                            margin-top: 0px;">Social Media Creatives</h4>
                                    </div>
                                    <!--end box__header-->
                                    <div class="box__content">
                                        <p>The designing, creation, and execution of static images, gifs, and layout
                                            plans
                                        </p>
                                    </div>
                                    <!--end box__content-->
                                </div>
                                <!--end box__wrapper-->
                            </div>
                            <!--end box-feature-->
                        </div>
                    </ng-template>
                    <ng-template swiperSlide>
                        <div class="col-12">
                            <div class="box">
                                <div class="box__wrapper" style="margin-right: 30px; margin-left: 30px;">
                                    <div class="box__header">
                                        <div class="box__image">
                                            <img src="assets/img/copy writing.png" class="" alt="">
                                        </div>
                                        <!--end box__image-->
                                        <h4 class="reveal" style="
                                            margin-top: 0px;">Copywriting</h4>
                                    </div>
                                    <!--end box__header-->
                                    <div class="box__content">
                                        <p>The writing and editing for on point text in regards to advertisements,
                                            social
                                            media posts, and other forms of marketing </p>
                                    </div>
                                    <!--end box__content-->
                                </div>
                                <!--end box__wrapper-->
                            </div>
                            <!--end box-feature-->
                        </div>
                    </ng-template>
                    <ng-template swiperSlide>
                        <div class="col-12">
                            <div class="box">
                                <div class="box__wrapper" style="margin-right: 30px; margin-left: 30px;">
                                    <div class="box__header">
                                        <div class="box__image">
                                            <img src="assets/img/photoshoot.png" class="" alt="">
                                        </div>
                                        <!--end box__image-->
                                        <h4 class="reveal" style="
                                            margin-top: 0px;">Photo Shoots</h4>
                                    </div>
                                    <!--end box__header-->
                                    <div class="box__content">
                                        <p>The conceptualisation, setting, clicking, and editing based on the brief for
                                            the
                                            brand right from products, to models, to layouts</p>
                                    </div>
                                    <!--end box__content-->
                                </div>
                                <!--end box__wrapper-->
                            </div>
                            <!--end box-feature-->
                        </div>
                    </ng-template>
                    <ng-template swiperSlide>
                        <div class="col-12">
                            <div class="box">
                                <div class="box__wrapper" style="margin-right: 30px; margin-left: 30px;">
                                    <div class="box__header">
                                        <div class="box__image">
                                            <img src="assets/img/video shoot.png" class="" alt="">
                                        </div>
                                        <!--end box__image-->
                                        <h4 class="reveal" style="
                                            margin-top: 0px;">Videography & Ad Shoots</h4>
                                    </div>
                                    <!--end box__header-->
                                    <div class="box__content">
                                        <p>The conceptualisation, scripting, shooting, and editing based on the brief
                                            for
                                            the brand right from products, to layouts, to ad shoots</p>
                                    </div>
                                    <!--end box__content-->
                                </div>
                                <!--end box__wrapper-->
                            </div>
                            <!--end box-feature-->
                        </div>
                    </ng-template>
                    <ng-template swiperSlide>
                        <div class="col-12">
                            <div class="box">
                                <div class="box__wrapper" style="margin-right: 30px; margin-left: 30px;">
                                    <div class="box__header">
                                        <div class="box__image">
                                            <img src="assets/img/print media.png" class="" alt="">
                                        </div>
                                        <!--end box__image-->
                                        <h4 class="reveal" style="
                                            margin-top: 0px;">Print Media Design</h4>
                                    </div>
                                    <!--end box__header-->
                                    <div class="box__content">
                                        <p>The conceptualisation, design, and execution for passing information of the
                                            brand
                                            through printed mediums</p>
                                    </div>
                                    <!--end box__content-->
                                </div>
                                <!--end box__wrapper-->
                            </div>
                            <!--end box-feature-->
                        </div>
                    </ng-template>
                    <ng-template swiperSlide>
                        <div class="col-12">
                            <div class="box">
                                <div class="box__wrapper" style="margin-right: 30px; margin-left: 30px;">
                                    <div class="box__header">
                                        <div class="box__image">
                                            <img src="assets/img/email.png" class="" alt="">
                                        </div>
                                        <!--end box__image-->
                                        <h4 class="reveal" style="
                                            margin-top: 0px;">Emailers</h4>
                                    </div>
                                    <!--end box__header-->
                                    <div class="box__content">
                                        <p>The design, creation, and execution for corporate, marketing and promotional
                                            needs</p>
                                    </div>
                                    <!--end box__content-->
                                </div>
                                <!--end box__wrapper-->
                            </div>
                            <!--end box-feature-->
                        </div>
                    </ng-template>
                    <ng-template swiperSlide>
                        <div class="col-12">
                            <div class="box">
                                <div class="box__wrapper" style="margin-right: 30px; ">
                                    <div class="box__header">
                                        <div class="box__image">
                                            <img src="assets/img/brand.png" class="" alt="">
                                        </div>
                                        <!--end box__image-->
                                        <h4 class="reveal" style="
                                            margin-top: 0px;">Branding</h4>
                                    </div>
                                    <!--end box__header-->
                                    <div class="box__content">
                                        <p>The designing, creation, and execution of a brand icon that connects users to
                                            its
                                            loyalty</p>
                                    </div>
                                    <!--end box__content-->
                                </div>
                                <!--end box__wrapper-->
                            </div>
                            <!--end box-feature-->
                        </div>
                    </ng-template>
                </swiper>
            </div>

        </section>


        <!-- For ipad -->
        <section class="pt" id="what-we-do">
            <div class="container d-none d-md-block d-lg-none">
                <!--  <div class="block__title">
    <h2 class="reveal">What We Do</h2>
</div>-->
                <!--end block-title-->
                <h2 style="margin-top: 140px;" class="reveal">What We Do</h2>
                <swiper [config]="config1" [navigation]="true" [watchSlidesVisibility]="false" [slidesPerGroup]="2"
                    [watchSlidesProgress]="true" [pagination]="{
        clickable: true
      }" [slidesPerView]="2" (swiper)="onSwiper($event)" (slideChange)="onSlideChange()"
                    [autoplay]="{ delay:5000,disableOnInteraction: false}">


                    <ng-template swiperSlide>
                        <div class="col-12 justify-content-center">
                            <div class="box1">
                                <div class="box__wrapper">
                                    <div class="box__header">
                                        <div class="box__image">
                                            <img src="assets/img/strategy.png" class="" alt="">
                                        </div><br>
                                        <!--end box__image-->
                                        <h4 class="reveal" style="
                            margin-top: 0px;"> Content Strategy</h4>
                                    </div>
                                    <!--end box__header-->
                                    <div class="box__content">
                                        <p>The planning, creation, and publication of the content plan for your brand
                                        </p>
                                    </div>
                                    <!--end box__content-->
                                </div>
                                <!--end box__wrapper-->
                            </div>
                            <!--end box-feature-->
                        </div>
                    </ng-template>
                    <ng-template swiperSlide>
                        <div class="col-12">
                            <div class="box1">
                                <div class="box__wrapper">
                                    <div class="box__header">
                                        <div class="box__image">
                                            <img src="assets/img/Content writing.png" class="" alt="">
                                        </div><br>
                                        <!--end box__image-->
                                        <h4 class="reveal" style="
                            margin-top: 0px;">Content Writing</h4>
                                    </div>
                                    <!--end box__header-->
                                    <div class="box__content">
                                        <p>The planning, writing, and editing for all types of content - websites,
                                            blogs,
                                            articles, script writing, and the rest</p>
                                    </div>
                                    <!--end box__content-->
                                </div>
                                <!--end box__wrapper-->
                            </div>
                            <!--end box-feature-->
                        </div>
                    </ng-template>
                    <ng-template swiperSlide>
                        <div class="col-12">
                            <div class="box1">
                                <div class="box__wrapper">
                                    <div class="box__header">
                                        <div class="box__image">
                                            <img src="assets/img/social1.png" class="" alt="">
                                        </div><br>
                                        <!--end box__image-->
                                        <h4 class="reveal" style="
                            margin-top: 0px;">Social Media Creatives</h4>
                                    </div>
                                    <!--end box__header-->
                                    <div class="box__content">
                                        <p>The designing, creation, and execution of static images, gifs, and layout
                                            plans
                                        </p>
                                    </div>
                                    <!--end box__content-->
                                </div>
                                <!--end box__wrapper-->
                            </div>
                            <!--end box-feature-->
                        </div>
                    </ng-template>
                    <ng-template swiperSlide>
                        <div class="col-12">
                            <div class="box1">
                                <div class="box__wrapper">
                                    <div class="box__header">
                                        <div class="box__image">
                                            <img src="assets/img/copy writing.png" class="" alt="">
                                        </div><br>
                                        <!--end box__image-->
                                        <h4 class="reveal" style="
                            margin-top: 0px;">Copywriting</h4>
                                    </div>
                                    <!--end box__header-->
                                    <div class="box__content">
                                        <p>The writing and editing for on point text in regards to advertisements,
                                            social
                                            media posts, and other forms of marketing </p>
                                    </div>
                                    <!--end box__content-->
                                </div>
                                <!--end box__wrapper-->
                            </div>
                            <!--end box-feature-->
                        </div>
                    </ng-template>
                    <ng-template swiperSlide>
                        <div class="col-12">
                            <div class="box1">
                                <div class="box__wrapper">
                                    <div class="box__header">
                                        <div class="box__image">
                                            <img src="assets/img/photoshoot.png" class="" alt="">
                                        </div><br>
                                        <!--end box__image-->
                                        <h4 class="reveal" style="
                            margin-top: 0px;">Photo Shoots</h4>
                                    </div>
                                    <!--end box__header-->
                                    <div class="box__content">
                                        <p>The conceptualisation, setting, clicking, and editing based on the brief for
                                            the
                                            brand right from products, to models, to layouts</p>
                                    </div>
                                    <!--end box__content-->
                                </div>
                                <!--end box__wrapper-->
                            </div>
                            <!--end box-feature-->
                        </div>
                    </ng-template>
                    <ng-template swiperSlide>
                        <div class="col-12">
                            <div class="box1">
                                <div class="box__wrapper">
                                    <div class="box__header">
                                        <div class="box__image">
                                            <img src="assets/img/video shoot.png" class="" alt="">
                                        </div><br>
                                        <!--end box__image-->
                                        <h4 class="reveal" style="
                            margin-top: 0px;">Videography & Ad Shoots</h4>
                                    </div>
                                    <!--end box__header-->
                                    <div class="box__content">
                                        <p>The conceptualisation, scripting, shooting, and editing based on the brief
                                            for
                                            the brand right from products, to layouts, to ad shoots</p>
                                    </div>
                                    <!--end box__content-->
                                </div>
                                <!--end box__wrapper-->
                            </div>
                            <!--end box-feature-->
                        </div>
                    </ng-template>
                    <ng-template swiperSlide>
                        <div class="col-12">
                            <div class="box1">
                                <div class="box__wrapper">
                                    <div class="box__header">
                                        <div class="box__image">
                                            <img src="assets/img/print media.png" class="" alt="">
                                        </div><br>
                                        <!--end box__image-->
                                        <h4 class="reveal" style="
                            margin-top: 0px;">Print Media Design</h4>
                                    </div>
                                    <!--end box__header-->
                                    <div class="box__content">
                                        <p>The conceptualisation, design, and execution for passing information of the
                                            brand
                                            through printed mediums</p>
                                    </div>
                                    <!--end box__content-->
                                </div>
                                <!--end box__wrapper-->
                            </div>
                            <!--end box-feature-->
                        </div>
                    </ng-template>
                    <ng-template swiperSlide>
                        <div class="col-12">
                            <div class="box1">
                                <div class="box__wrapper">
                                    <div class="box__header">
                                        <div class="box__image">
                                            <img src="assets/img/email.png" class="" alt="">
                                        </div><br>
                                        <!--end box__image-->
                                        <h4 class="reveal" style="
                            margin-top: 0px;">Emailers</h4>
                                    </div>
                                    <!--end box__header-->
                                    <div class="box__content">
                                        <p>The design, creation, and execution for corporate, marketing and promotional
                                            needs</p>
                                    </div>
                                    <!--end box__content-->
                                </div>
                                <!--end box__wrapper-->
                            </div>
                            <!--end box-feature-->
                        </div>
                    </ng-template>
                    <ng-template swiperSlide>
                        <div class="col-12">
                            <div class="box1">
                                <div class="box__wrapper">
                                    <div class="box__header">
                                        <div class="box__image">
                                            <img src="assets/img/brand.png" class="" alt="">
                                        </div><br>
                                        <!--end box__image-->
                                        <h4 class="reveal" style="
                            margin-top: 0px;">Branding</h4>
                                    </div>
                                    <!--end box__header-->
                                    <div class="box__content">
                                        <p>The designing, creation, and execution of a brand icon that connects users to
                                            its
                                            loyalty</p>
                                    </div>
                                    <!--end box__content-->
                                </div>
                                <!--end box__wrapper-->
                            </div>
                            <!--end box-feature-->
                        </div>
                    </ng-template>
                </swiper>
            </div>

        </section>

        <!-- For mobile -->
        <section class="" id="what-we-do">
            <div class="container  d-block d-md-none d-lg-none">
                <!--  <div class="block__title">
        <h2 class="reveal">What We Do</h2>
    </div>-->
                <!--end block-title-->
                <h2 style="margin-top:100px;" class="reveal">What We Do</h2>

                <swiper [config]="config1" [navigation]="true" [watchSlidesVisibility]="false" [slidesPerGroup]="2"
                    [watchSlidesProgress]="true" [pagination]="{
            clickable: true
          }" [slidesPerView]="2" (swiper)="onSwiper($event)" (slideChange)="onSlideChange()">
                    <!-- [autoplay]="{ delay:5000,disableOnInteraction: false}"-->


                    <ng-template swiperSlide>
                        <div class="col-12 justify-content-center">
                            <div class="" style="margin-left: 40px;">
                                <div class="box__wrapper">
                                    <div class="box__header">
                                        <div class="box__image">
                                            <img src="assets/img/strategy.png" class="" alt="">
                                        </div><br>
                                        <!--end box__image-->
                                        <h4 class="reveal" style="
                                margin-top: 0px;"> Content Strategy</h4>
                                    </div>
                                    <!--end box__header-->
                                    <div class="box__content">
                                        <p>The planning, creation, and publication of the content plan for your brand
                                        </p>
                                    </div>
                                    <!--end box__content-->
                                </div>
                                <!--end box__wrapper-->
                            </div>
                            <!--end box-feature-->
                        </div>
                    </ng-template>
                    <ng-template swiperSlide>
                        <div class="col-12">
                            <div class="" style="margin-right: 20px;">
                                <div class="box__wrapper">
                                    <div class="box__header">
                                        <div class="box__image">
                                            <img src="assets/img/Content writing.png" class="" alt="">
                                        </div><br>
                                        <!--end box__image-->
                                        <h4 class="reveal" style="
                                margin-top: 0px;">Content Writing</h4>
                                    </div>
                                    <!--end box__header-->
                                    <div class="box__content">
                                        <p>The planning, writing, and editing for all types of content - websites,
                                            blogs,
                                            articles, script writing, and the rest</p>
                                    </div>
                                    <!--end box__content-->
                                </div>
                                <!--end box__wrapper-->
                            </div>
                            <!--end box-feature-->
                        </div>
                    </ng-template>
                    <ng-template swiperSlide>
                        <div class="col-12">
                            <div class="" style="margin-left: 40px;">
                                <div class="box__wrapper">
                                    <div class="box__header">
                                        <div class="box__image">
                                            <img src="assets/img/social1.png" class="" alt="">
                                        </div><br>
                                        <!--end box__image-->
                                        <h4 class="reveal" style="
                                margin-top: 0px;">Social Media Creatives</h4>
                                    </div>
                                    <!--end box__header-->
                                    <div class="box__content">
                                        <p>The designing, creation, and execution of static images, gifs, and layout
                                            plans
                                        </p>
                                    </div>
                                    <!--end box__content-->
                                </div>
                                <!--end box__wrapper-->
                            </div>
                            <!--end box-feature-->
                        </div>
                    </ng-template>
                    <ng-template swiperSlide>
                        <div class="col-12">
                            <div class="" style="margin-right: 20px;">
                                <div class="box__wrapper">
                                    <div class="box__header">
                                        <div class="box__image">
                                            <img src="assets/img/copy writing.png" class="" alt="">
                                        </div><br>
                                        <!--end box__image-->
                                        <h4 class="reveal" style="
                                margin-top: 0px;">Copywriting</h4>
                                    </div>
                                    <!--end box__header-->
                                    <div class="box__content">
                                        <p>The writing and editing for on point text in regards to advertisements,
                                            social
                                            media posts, and other forms of marketing </p>
                                    </div>
                                    <!--end box__content-->
                                </div>
                                <!--end box__wrapper-->
                            </div>
                            <!--end box-feature-->
                        </div>
                    </ng-template>
                    <ng-template swiperSlide>
                        <div class="col-12">
                            <div class="" style="margin-left: 30px;">
                                <div class="box__wrapper">
                                    <div class="box__header">
                                        <div class="box__image">
                                            <img src="assets/img/photoshoot.png" class="" alt="">
                                        </div><br>
                                        <!--end box__image-->
                                        <h4 class="reveal" style="
                                margin-top: 0px;">Photo Shoots</h4>
                                    </div>
                                    <!--end box__header-->
                                    <div class="box__content">
                                        <p>The conceptualisation, setting, clicking, and editing based on the brief for
                                            the
                                            brand right from products, to models, to layouts</p>
                                    </div>
                                    <!--end box__content-->
                                </div>
                                <!--end box__wrapper-->
                            </div>
                            <!--end box-feature-->
                        </div>
                    </ng-template>
                    <ng-template swiperSlide>
                        <div class="col-12">
                            <div class="" style="margin-right: 20px;">
                                <div class="box__wrapper">
                                    <div class="box__header">
                                        <div class="box__image">
                                            <img src="assets/img/video shoot.png" class="" alt="">
                                        </div><br>
                                        <!--end box__image-->
                                        <h4 class="reveal" style="
                                margin-top: 0px;">Videography & Ad Shoots</h4>
                                    </div>
                                    <!--end box__header-->
                                    <div class="box__content">
                                        <p>The conceptualisation, scripting, shooting, and editing based on the brief
                                            for
                                            the brand right from products, to layouts, to ad shoots</p>
                                    </div>
                                    <!--end box__content-->
                                </div>
                                <!--end box__wrapper-->
                            </div>
                            <!--end box-feature-->
                        </div>
                    </ng-template>
                    <ng-template swiperSlide>
                        <div class="col-12">
                            <div class="" style="margin-left: 40px;">
                                <div class="box__wrapper">
                                    <div class="box__header">
                                        <div class="box__image">
                                            <img src="assets/img/print media.png" class="" alt="">
                                        </div><br>
                                        <!--end box__image-->
                                        <h4 class="reveal" style="
                                margin-top: 0px;">Print Media Design</h4>
                                    </div>
                                    <!--end box__header-->
                                    <div class="box__content">
                                        <p>The conceptua-<br>lisation, design, and execution for passing information of
                                            the
                                            brand
                                            through printed mediums</p>
                                    </div>
                                    <!--end box__content-->
                                </div>
                                <!--end box__wrapper-->
                            </div>
                            <!--end box-feature-->
                        </div>
                    </ng-template>
                    <ng-template swiperSlide>
                        <div class="col-12">
                            <div class="" style="margin-left: 20px;">
                                <div class="box__wrapper">
                                    <div class="box__header">
                                        <div class="box__image">
                                            <img src="assets/img/email.png" class="" alt="">
                                        </div><br>
                                        <!--end box__image-->
                                        <h4 class="reveal" style="
                                margin-top: 0px;">Emailers</h4>
                                    </div>
                                    <!--end box__header-->
                                    <div class="box__content">
                                        <p>The design, creation, and execution for corporate, marketing<br> and
                                            promotional
                                            needs</p>
                                    </div>
                                    <!--end box__content-->
                                </div>
                                <!--end box__wrapper-->
                            </div>
                            <!--end box-feature-->
                        </div>
                    </ng-template>
                    <ng-template swiperSlide>
                        <div class="col-12">
                            <div class="" style="margin-right: 25px;">
                                <div class="box__wrapper">
                                    <div class="box__header">
                                        <div class="box__image">
                                            <img src="assets/img/brand.png" class="" alt="">
                                        </div><br>
                                        <!--end box__image-->
                                        <h4 class="reveal" style="
                                margin-top: 0px;">Branding</h4>
                                    </div>
                                    <!--end box__header-->
                                    <div class="box__content">
                                        <p>The designing, creation, and execution of a brand icon that connects users to
                                            its
                                            loyalty</p>
                                    </div>
                                    <!--end box__content-->
                                </div>
                                <!--end box__wrapper-->
                            </div>
                            <!--end box-feature-->
                        </div>
                    </ng-template>
                </swiper>

            </div>

        </section>






        <!--end #what-we-do.block-->

        <!--OUR WORKS ********************************************************************************************-->

        <section class="pb-0 block" id="the-work">
            <div class="container  d-none d-md-none d-lg-block" style="padding-top: 130px;">
                <!-- <div class="block__title">
                    <h2 class="reveal">The Work</h2>
                </div>-->
                <h2 class="reveal">The Work</h2>
                <swiper [config]="config" [navigation]="true" [watchSlidesVisibility]="false"
                    [watchSlidesProgress]="true" [pagination]="{clickable: true}" (swiper)="onSwiper($event)"
                    (slideChange)="onSlideChange()">
                    <ng-template swiperSlide> <a target="_blank"><img src="/assets/img/portfolio/whiskpath.png"
                                class="img-fluid "></a></ng-template>
                    <ng-template swiperSlide><a target="_blank"> <img src="/assets/img/portfolio/pure91.png"
                                class="img-fluid " style="width: 85%; height: 85%; padding-top: 8px;">
                        </a></ng-template>
                    <ng-template swiperSlide><a target="_blank"> <img src="/assets/img/portfolio/lacurry.png"
                                class="img-fluid" style="width: 90%; height: 90%; padding-top: 80px;"> </a>
                    </ng-template>
                    <ng-template swiperSlide><a target="_blank"><img src="/assets/img/portfolio/sabjiwala.png"
                                class="img-fluid "
                                style="padding-top: 20px; padding-right: 25px; width: 300%; height: 100%;">
                        </a></ng-template>
                    <ng-template swiperSlide><a target="_blank"><img src="/assets/img/portfolio/bmixer.png"
                                class="img-fluid " style="width: 300%; height: 100%;">
                        </a></ng-template>
                    <ng-template swiperSlide> <img src="/assets/img/portfolio/biryaniguru.png" class="img-fluid "
                            style="width: 90%; height: 90%; padding-top: 10px; ;"></ng-template>
                    <ng-template swiperSlide><img src="/assets/img/portfolio/unimatcha.png" class="img-fluid "
                            style="padding-top: 80px; padding-right: 40px; ; width: 300%; height: 100%;">
                    </ng-template>
                    <ng-template swiperSlide><img src="/assets/img/All clients/Cennet events.png" class="img-fluid "
                            style="padding-top: 40px; padding-right: 40px; ; width: 80%; height: 100%;">
                    </ng-template>
                    <ng-template swiperSlide><img src="/assets/img/All clients/Femingo.png" class="img-fluid "
                            style="padding-top: 92px; padding-right: 40px; ; width: 300%; height: 100%;">
                    </ng-template>
                    <ng-template swiperSlide><img src="/assets/img/All clients/Geetee Carriers.png" class="img-fluid "
                            style="padding-top: 80px; padding-right: 40px; ; width: 300%; height: 100%;">
                    </ng-template>
                    <ng-template swiperSlide><img src="/assets/img/All clients/Godavari Paper Mills.png"
                            class="img-fluid "
                            style="padding-top: 80px; padding-right: 40px; ; width: 300%; height: 100%;">
                    </ng-template>
                    <ng-template swiperSlide><img src="assets/img/All clients/Inner Sense.png" class="img-fluid "
                            style="padding-top: 50px; padding-right: 40px; ; width: 80%; height: 100%;">
                    </ng-template>
                    <ng-template swiperSlide><img src="assets/img/All clients/ParkIt.png" class="img-fluid "
                            style="padding-top: 50px; padding-right: 40px; ; width: 70%; height: 100%;">
                    </ng-template>
                    <ng-template swiperSlide><img src="assets/img/All clients/Soul +Fix.png" class="img-fluid "
                            style="padding-top: 50px; padding-right: 40px; ; width: 70%; height: 100%;">
                    </ng-template>
                    <ng-template swiperSlide><img src="assets/img/All clients/The Sonic Den.png" class="img-fluid "
                            style="padding-top: 50px; padding-right: 40px; ; width: 300%; height: 100%;">
                    </ng-template>
                    <ng-template swiperSlide><img src="/assets/img/All clients/UniBourne.png" class="img-fluid "
                            style="padding-top: 80px; padding-right: 40px; ; width: 300%; height: 100%;">
                    </ng-template>
                    <ng-template swiperSlide><img src="/assets/img/All clients/Zama Cocoa.png" class="img-fluid "
                            style="padding-top: 50px; padding-right: 40px; ; width: 300%; height: 100%;">
                    </ng-template>
                    <ng-template swiperSlide><img src="/assets/img/All clients/White Light Foods.jpg" class="img-fluid "
                            style="padding-top: 50px; padding-right: 40px; ; width: 300%; height: 100%;">
                    </ng-template>

                </swiper>

            </div>

            <!-- <div class="gallery skew-down-left skew-up-right">
              <div class="container-fluid px-0">
                  <div class="row no-gutters">
                      <div class="col-md-4">
                          <a href="http://www.youtube.com/watch?v=0O2aH4XLbto" class="gallery__image video-popup">
                              <div class="gallery__caption">
                                  <h3>
                                      <i class="fa fa-play-circle"></i>
                                  </h3>
                                  <h5>Interior Design</h5>
                                  <h3 class="reveal">Bathroom Refresh</h3>
                              </div>
                              <div class="img-into-bg">
                                  <img src="assets/img/img-01.jpg" alt="">
                              </div>
                          </a>
                          
                      </div>
                     
                      <div class="col-md-4">
                          <a href="assets/img/img-02.jpg" class="gallery__image popup-image">
                              <div class="gallery__caption">
                                  <h5>Architecture</h5>
                                  <h3 class="reveal">Hills Apartment</h3>
                              </div>
                              <div class="img-into-bg">
                                  <img src="assets/img/img-02.jpg" alt="">
                              </div>
                          </a>
                          
                      </div>
                      
                      <div class="col-md-4">
                          <a href="assets/img/img-03.jpg" class="gallery__image popup-image">
                              <div class="gallery__caption">
                                  <h5>Branding</h5>
                                  <h3 class="reveal">Story Restaurant</h3>
                              </div>
                              <div class="img-into-bg">
                                  <img src="assets/img/img-03.jpg" alt="">
                              </div>
                          </a>
                         
                      </div>
                      
                      <div class="col-md-4">
                          <a href="assets/img/img-04.jpg" class="gallery__image popup-image">
                              <div class="gallery__caption">
                                  <h5>Industrial Design</h5>
                                  <h3 class="reveal">Room Furniture</h3>
                              </div>
                              <div class="img-into-bg">
                                  <img src="assets/img/img-04.jpg" alt="">
                              </div>
                          </a>
                          
                      </div>
                      
                      <div class="col-md-4">
                          <a href="assets/img/img-05.jpg" class="gallery__image popup-image">
                              <div class="gallery__caption">
                                  <h5>Branding</h5>
                                  <h3 class="reveal">Maracana Cups</h3>
                              </div>
                              <div class="img-into-bg">
                                  <img src="assets/img/img-05.jpg" alt="">
                              </div>
                          </a>
                          
                      </div>
                      
                      <div class="col-md-4">
                          <a href="assets/img/img-06.jpg" class="gallery__image popup-image">
                              <div class="gallery__caption">
                                  <h5>Art</h5>
                                  <h3 class="reveal">Timeless Motion</h3>
                              </div>
                              <div class="img-into-bg">
                                  <img src="assets/img/img-06.jpg" alt="">
                              </div>
                          </a>
                          
                      </div>
                     
                  </div>
                 
              </div>
              
          </div> -->

        </section>

        <!-- #FOR ipad-THE WORK -->
        <section class="" id="the-work">
            <div class="container d-none d-md-block d-lg-none">
                <!-- <div class="block__title">
                    <h2 class="reveal">The Work</h2>
                </div>-->
                <h2 style="margin-top:90px;" class="reveal">The Work</h2>
                <swiper [config]="config" [navigation]="true" [watchSlidesVisibility]="false"
                    [watchSlidesProgress]="true" [pagination]="{clickable: true}" (swiper)="onSwiper($event)"
                    (slideChange)="onSlideChange()">
                    <ng-template swiperSlide> <a target="_blank"><img src="/assets/img/portfolio/whiskpath.png"
                                class="img-fluid "></a></ng-template>
                    <ng-template swiperSlide><a target="_blank"> <img src="/assets/img/portfolio/pure91.png"
                                class="img-fluid " style="width: 85%; height: 85%; padding-top: 8px;">
                        </a></ng-template>
                    <ng-template swiperSlide><a target="_blank"> <img src="/assets/img/portfolio/lacurry.png"
                                class="img-fluid" style="width: 90%; height: 90%; padding-top: 80px;"> </a>
                    </ng-template>
                    <ng-template swiperSlide><a target="_blank"><img src="/assets/img/portfolio/sabjiwala.png"
                                class="img-fluid "
                                style="padding-top: 20px; padding-right: 25px; width: 300%; height: 100%;">
                        </a></ng-template>
                    <ng-template swiperSlide><a target="_blank"><img src="/assets/img/portfolio/bmixer.png"
                                class="img-fluid " style="width: 300%; height: 100%;">
                        </a></ng-template>
                    <ng-template swiperSlide> <img src="/assets/img/portfolio/biryaniguru.png" class="img-fluid "
                            style="width: 90%; height: 90%; padding-top: 10px; ;"></ng-template>
                    <ng-template swiperSlide><img src="/assets/img/portfolio/unimatcha.png" class="img-fluid "
                            style="padding-top: 80px; padding-right: 40px; ; width: 300%; height: 100%;">
                    </ng-template>
                    <ng-template swiperSlide><img src="/assets/img/All clients/Cennet events.png" class="img-fluid "
                            style="padding-top: 40px; padding-right: 40px; ; width: 80%; height: 100%;">
                    </ng-template>
                    <ng-template swiperSlide><img src="/assets/img/All clients/Femingo.png" class="img-fluid "
                            style="padding-top: 92px; padding-right: 40px; ; width: 300%; height: 100%;">
                    </ng-template>
                    <ng-template swiperSlide><img src="/assets/img/All clients/Geetee Carriers.png" class="img-fluid "
                            style="padding-top: 80px; padding-right: 40px; ; width: 300%; height: 100%;">
                    </ng-template>
                    <ng-template swiperSlide><img src="/assets/img/All clients/Godavari Paper Mills.png"
                            class="img-fluid "
                            style="padding-top: 80px; padding-right: 40px; ; width: 300%; height: 100%;">
                    </ng-template>
                    <ng-template swiperSlide><img src="assets/img/All clients/Inner Sense.png" class="img-fluid "
                            style="padding-top: 50px; padding-right: 40px; ; width: 80%; height: 100%;">
                    </ng-template>
                    <ng-template swiperSlide><img src="assets/img/All clients/ParkIt.png" class="img-fluid "
                            style="padding-top: 50px; padding-right: 40px; ; width: 70%; height: 100%;">
                    </ng-template>
                    <ng-template swiperSlide><img src="assets/img/All clients/Soul +Fix.png" class="img-fluid "
                            style="padding-top: 50px; padding-right: 40px; ; width: 70%; height: 100%;">
                    </ng-template>
                    <ng-template swiperSlide><img src="assets/img/All clients/The Sonic Den.png" class="img-fluid "
                            style="padding-top: 50px; padding-right: 40px; ; width: 300%; height: 100%;">
                    </ng-template>
                    <ng-template swiperSlide><img src="/assets/img/All clients/UniBourne.png" class="img-fluid "
                            style="padding-top: 80px; padding-right: 40px; ; width: 300%; height: 100%;">
                    </ng-template>
                    <ng-template swiperSlide><img src="/assets/img/All clients/Zama Cocoa.png" class="img-fluid "
                            style="padding-top: 50px; padding-right: 40px; ; width: 300%; height: 100%;">
                    </ng-template>
                    <ng-template swiperSlide><img src="/assets/img/All clients/White Light Foods.jpg" class="img-fluid "
                            style="padding-top: 50px; padding-right: 40px; ; width: 300%; height: 100%;">
                    </ng-template>

                </swiper>

            </div>

            <!-- <div class="gallery skew-down-left skew-up-right">
              <div class="container-fluid px-0">
                  <div class="row no-gutters">
                      <div class="col-md-4">
                          <a href="http://www.youtube.com/watch?v=0O2aH4XLbto" class="gallery__image video-popup">
                              <div class="gallery__caption">
                                  <h3>
                                      <i class="fa fa-play-circle"></i>
                                  </h3>
                                  <h5>Interior Design</h5>
                                  <h3 class="reveal">Bathroom Refresh</h3>
                              </div>
                              <div class="img-into-bg">
                                  <img src="assets/img/img-01.jpg" alt="">
                              </div>
                          </a>
                          
                      </div>
                     
                      <div class="col-md-4">
                          <a href="assets/img/img-02.jpg" class="gallery__image popup-image">
                              <div class="gallery__caption">
                                  <h5>Architecture</h5>
                                  <h3 class="reveal">Hills Apartment</h3>
                              </div>
                              <div class="img-into-bg">
                                  <img src="assets/img/img-02.jpg" alt="">
                              </div>
                          </a>
                          
                      </div>
                      
                      <div class="col-md-4">
                          <a href="assets/img/img-03.jpg" class="gallery__image popup-image">
                              <div class="gallery__caption">
                                  <h5>Branding</h5>
                                  <h3 class="reveal">Story Restaurant</h3>
                              </div>
                              <div class="img-into-bg">
                                  <img src="assets/img/img-03.jpg" alt="">
                              </div>
                          </a>
                         
                      </div>
                      
                      <div class="col-md-4">
                          <a href="assets/img/img-04.jpg" class="gallery__image popup-image">
                              <div class="gallery__caption">
                                  <h5>Industrial Design</h5>
                                  <h3 class="reveal">Room Furniture</h3>
                              </div>
                              <div class="img-into-bg">
                                  <img src="assets/img/img-04.jpg" alt="">
                              </div>
                          </a>
                          
                      </div>
                      
                      <div class="col-md-4">
                          <a href="assets/img/img-05.jpg" class="gallery__image popup-image">
                              <div class="gallery__caption">
                                  <h5>Branding</h5>
                                  <h3 class="reveal">Maracana Cups</h3>
                              </div>
                              <div class="img-into-bg">
                                  <img src="assets/img/img-05.jpg" alt="">
                              </div>
                          </a>
                          
                      </div>
                      
                      <div class="col-md-4">
                          <a href="assets/img/img-06.jpg" class="gallery__image popup-image">
                              <div class="gallery__caption">
                                  <h5>Art</h5>
                                  <h3 class="reveal">Timeless Motion</h3>
                              </div>
                              <div class="img-into-bg">
                                  <img src="assets/img/img-06.jpg" alt="">
                              </div>
                          </a>
                          
                      </div>
                     
                  </div>
                 
              </div>
              
          </div> -->

        </section>

        <!-- #FOR MOBILE-THE WORK -->

        <section class="" id="the-work">
            <div class="container d-block d-md-none d-lg-none ">
                <!-- <div class="block__title">
                    <h2 class="reveal">The Work</h2>
                </div>-->
                <h2 style="margin-top:75px;" class="reveal">The Work</h2>
                <swiper [config]="config" [navigation]="true" [watchSlidesVisibility]="false"
                    [watchSlidesProgress]="true" [pagination]="{clickable: true}" (swiper)="onSwiper($event)"
                    (slideChange)="onSlideChange()">
                    <ng-template swiperSlide> <a target="_blank"><img src="/assets/img/portfolio/whiskpath.png"
                                class="img-fluid "></a></ng-template>
                    <ng-template swiperSlide><a target="_blank"> <img src="/assets/img/portfolio/pure91.png"
                                class="img-fluid " style="width: 85%; height: 85%; padding-top: 8px;">
                        </a></ng-template>
                    <ng-template swiperSlide><a target="_blank"> <img src="/assets/img/portfolio/lacurry.png"
                                class="img-fluid" style="width: 90%; height: 90%; padding-top: 30px;"> </a>
                    </ng-template>
                    <ng-template swiperSlide><a target="_blank"><img src="/assets/img/portfolio/sabjiwala.png"
                                class="img-fluid "
                                style="padding-top: 20px; padding-right: 25px; width: 300%; height: 100%;">
                        </a></ng-template>
                    <ng-template swiperSlide><a target="_blank"><img src="/assets/img/portfolio/bmixer.png"
                                class="img-fluid " style="width: 300%; height: 100%;">
                        </a></ng-template>
                    <ng-template swiperSlide> <img src="/assets/img/portfolio/biryaniguru.png" class="img-fluid "
                            style="width: 90%; height: 90%; padding-top: 10px; ;"></ng-template>
                    <ng-template swiperSlide><img src="/assets/img/portfolio/unimatcha.png" class="img-fluid "
                            style="padding-top: 50px; padding-right: 40px; ; width: 300%; height: 100%;">
                    </ng-template>
                    <ng-template swiperSlide><img src="/assets/img/All clients/Cennet events.png" class="img-fluid "
                            style="padding-top: 40px; padding-right: 40px; ; width: 80%; height: 100%;">
                    </ng-template>
                    <ng-template swiperSlide><img src="/assets/img/All clients/Femingo.png" class="img-fluid "
                            style="padding-top: 55px; padding-right: 20px; ; width: 300%; height: 100%;">
                    </ng-template>
                    <ng-template swiperSlide><img src="/assets/img/All clients/Geetee Carriers.png" class="img-fluid "
                            style="padding-top: 55px; padding-right: 20px; ; width: 300%; height: 100%;">
                    </ng-template>
                    <ng-template swiperSlide><img src="/assets/img/All clients/Godavari Paper Mills.png"
                            class="img-fluid "
                            style="padding-top: 55px; padding-right: 20px; ; width: 300%; height: 100%;">
                    </ng-template>
                    <ng-template swiperSlide><img src="assets/img/All clients/Inner Sense.png" class="img-fluid "
                            style="padding-top: 35px; padding-right: 70px; ; width: 100%; height: 100%;">
                    </ng-template>
                    <ng-template swiperSlide><img src="assets/img/All clients/ParkIt.png" class="img-fluid "
                            style="padding-top: 30px; padding-right: 75px; ; width: 100%; height: 100%;">
                    </ng-template>
                    <ng-template swiperSlide><img src="assets/img/All clients/Soul +Fix.png" class="img-fluid "
                            style="padding-top: 30px; padding-right: 40px; ; width: 80%; height: 100%;">
                    </ng-template>
                    <ng-template swiperSlide><img src="assets/img/All clients/The Sonic Den.png" class="img-fluid "
                            style="padding-top: 30px; padding-right: 40px; ; width: 300%; height: 100%;">
                    </ng-template>
                    <ng-template swiperSlide><img src="/assets/img/All clients/UniBourne.png" class="img-fluid "
                            style="padding-top: 55px; padding-right: 35px; ; width: 300%; height: 100%;">
                    </ng-template>
                    <ng-template swiperSlide><img src="/assets/img/All clients/Zama Cocoa.png" class="img-fluid "
                            style="padding-top: 40px; padding-right: 35px; ; width: 300%; height: 100%;">
                    </ng-template>
                    <ng-template swiperSlide><img src="/assets/img/All clients/White Light Foods.jpg" class="img-fluid "
                            style="padding-top: 55px; padding-right: 35px; ; width: 300%; height: 100%;">
                    </ng-template>

                </swiper>

            </div>

            <!-- <div class="gallery skew-down-left skew-up-right">
              <div class="container-fluid px-0">
                  <div class="row no-gutters">
                      <div class="col-md-4">
                          <a href="http://www.youtube.com/watch?v=0O2aH4XLbto" class="gallery__image video-popup">
                              <div class="gallery__caption">
                                  <h3>
                                      <i class="fa fa-play-circle"></i>
                                  </h3>
                                  <h5>Interior Design</h5>
                                  <h3 class="reveal">Bathroom Refresh</h3>
                              </div>
                              <div class="img-into-bg">
                                  <img src="assets/img/img-01.jpg" alt="">
                              </div>
                          </a>
                          
                      </div>
                     
                      <div class="col-md-4">
                          <a href="assets/img/img-02.jpg" class="gallery__image popup-image">
                              <div class="gallery__caption">
                                  <h5>Architecture</h5>
                                  <h3 class="reveal">Hills Apartment</h3>
                              </div>
                              <div class="img-into-bg">
                                  <img src="assets/img/img-02.jpg" alt="">
                              </div>
                          </a>
                          
                      </div>
                      
                      <div class="col-md-4">
                          <a href="assets/img/img-03.jpg" class="gallery__image popup-image">
                              <div class="gallery__caption">
                                  <h5>Branding</h5>
                                  <h3 class="reveal">Story Restaurant</h3>
                              </div>
                              <div class="img-into-bg">
                                  <img src="assets/img/img-03.jpg" alt="">
                              </div>
                          </a>
                         
                      </div>
                      
                      <div class="col-md-4">
                          <a href="assets/img/img-04.jpg" class="gallery__image popup-image">
                              <div class="gallery__caption">
                                  <h5>Industrial Design</h5>
                                  <h3 class="reveal">Room Furniture</h3>
                              </div>
                              <div class="img-into-bg">
                                  <img src="assets/img/img-04.jpg" alt="">
                              </div>
                          </a>
                          
                      </div>
                      
                      <div class="col-md-4">
                          <a href="assets/img/img-05.jpg" class="gallery__image popup-image">
                              <div class="gallery__caption">
                                  <h5>Branding</h5>
                                  <h3 class="reveal">Maracana Cups</h3>
                              </div>
                              <div class="img-into-bg">
                                  <img src="assets/img/img-05.jpg" alt="">
                              </div>
                          </a>
                          
                      </div>
                      
                      <div class="col-md-4">
                          <a href="assets/img/img-06.jpg" class="gallery__image popup-image">
                              <div class="gallery__caption">
                                  <h5>Art</h5>
                                  <h3 class="reveal">Timeless Motion</h3>
                              </div>
                              <div class="img-into-bg">
                                  <img src="assets/img/img-06.jpg" alt="">
                              </div>
                          </a>
                          
                      </div>
                     
                  </div>
                 
              </div>
              
          </div> -->

        </section>

        <!-- .row end -->
        <!--ABOUT US ********************************************************************************************-->

        <section class="" id="about-us">
            <div class="container" style="padding-top: 120px; padding-bottom: 100px;">
                <div class="block__wrapper">
                    <!-- <div class="block__title">
                        <h2 class="reveal">About Us</h2>
                    </div>-->
                    <!--end block-title-->
                    <h2 class="reveal">About Us</h2>
                    <div class="row">
                        <div class="col-md-7">

                            <p>
                                <br> Starting afresh in the third decade of this century, Disco & Pretzel is a creative
                                studio starting out from Bombay, India. That is what we are here for; to redefine
                                the
                                science of content. To begin a trend, one needs to keep experimenting with their
                                work
                                and that is the core principle of Disco & Pretzel - to create challenges for
                                ourselves
                            </p>
                            <!--   <a href="#contact" class="btn btn-default mb-4">Contact Us</a>-->
                        </div>
                        <!--end col-md-7-->
                        <div class="col-sm-12 col-md-5">
                            <img class="width-100
                                 shadow rounded-corners" src="assets/img/about1.png" alt="">
                        </div>
                        <!--end col-md-5-->
                    </div>
                    <!--end row-->
                </div>
                <!--end block__wrapper-->
            </div>
            <!--end container-->
        </section>

        <!--end #about-us.block-->

        <!--THE TEAM ********************************************************************************************-->

        <section class="skew-down-left skew-up-right " id="the-team">
            <div class="container d-none d-md-none d-lg-block">
                <div class="block__wrapper">
                    <!--<div class="block__title">
                        <h2 class="reveal">The Team</h2>
                    </div>-->
                    <!--end block-title-->
                    <h2 style="margin-top: 130px ;" class="reveal">The Team </h2>
                    <div class="row justify-content-center">
                        <div class="col-6 col-sm-6 col-md-6 col-lg-6">
                            <div class="person">
                                <h4 class="reveal">Sim Ferns</h4>
                                <h5>Co-Founder<br>(Dream Alchemist)</h5>
                                <div class="person__image">
                                    <a href="https://www.instagram.com/_simrnoff_/" target="_blank">
                                        <div class="person__social">

                                            <i class="fa fa-instagram"></i>

                                        </div>
                                    </a>
                                    <div class="img-into-bg">
                                        <img class="img-fluid" src="assets/img/simferns.png" alt="">
                                    </div>
                                </div>
                                <!--end person__image-->
                            </div>
                            <!--end person-->
                        </div>
                        <!--end col-md-3-->
                        <div class="col-6  col-sm-6 col-md-6 col-lg-6">
                            <div class="person">
                                <h4 class="reveal">Prithvi Patel</h4>
                                <h5>Co-Founder<br>(Chief Enjoyment Officer)</h5>
                                <div class="person__image">
                                    <a href="https://www.instagram.com/prithvi2803/" target="_blank">

                                        <div class="person__social">
                                            <i class="fa fa-instagram"></i>

                                        </div>
                                    </a>
                                    <div class="img-into-bg">
                                        <img class="img-fluid" src="assets/img/prithvi.png " alt="">
                                    </div>
                                </div>
                                <!--end person__image-->
                            </div>
                            <!--end person-->
                        </div>
                        <!--end col-md-3-->


                        <!-- </div> -->
                        <!--end row-->


                        <!--2nd row-->

                        <!-- <div class="row" style="padding-left: 260px;"> -->
                        <div class="col-6 col-sm-6 col-md-6 col-lg-4">
                            <div class="person">
                                <h4 class="reveal">Sumitava Ghosh</h4>
                                <h5>(Crayon Evangelist)</h5>
                                <div class="person__image">
                                    <a href="https://instagram.com/iamsumitava?igshid=YmMyMTA2M2Y=" target="_blank">

                                        <div class="person__social">
                                            <i class="fa fa-instagram"></i>

                                        </div>
                                    </a>
                                    <div class="img-into-bg"
                                        style="background-image: url('assets/img/Sumitava Ghosh.png')">
                                        <img class="img-fluid" src="assets/img/Sumitava Ghosh.png" alt="">
                                    </div>
                                </div>
                                <!--end person__image-->
                            </div>
                            <!--end person-->
                        </div>
                        <div class="col-6 col-sm-6 col-md-6 col-lg-4 mb">
                            <div class="person">
                                <h4 class="reveal">Dhruti Dedhia</h4>
                                <h5>(Hyphenated-Specialist) </h5>
                                <div class="person__image">
                                    <a href="https://www.instagram.com/dedhia.words/" target="_blank">

                                        <div class="person__social">
                                            <i class="fa fa-instagram"></i>

                                        </div>
                                    </a>
                                    <div class="img-into-bg">
                                        <img class="img-fluid" src="assets/img/dhruti.png" alt="">
                                    </div>
                                </div>
                                <!--end person__image-->
                            </div>
                            <!--end person-->
                        </div>
                        <div class="col-6 col-sm-6 col-md-6 col-lg-4 mb">
                            <div class="person">
                                <h4 class="reveal">Shefali Sehgal</h4>
                                <h5>(Crayon Evangelist) </h5>
                                <div class="person__image">
                                    <a href="https://www.instagram.com/sheffalisehgal99/" target="_blank">

                                        <div class="person__social">
                                            <i class="fa fa-instagram"></i>

                                        </div>
                                    </a>
                                    <div class="img-into-bg">
                                        <img class="img-fluid" src="assets/img/shefali.png" alt="">
                                    </div>
                                </div>
                                <!--end person__image-->
                            </div>
                            <!--end person-->
                        </div>
                    </div>
                </div>
                <!--end block__wrapper-->
            </div>
            <!--end container-->
            <div class="background-wrapper" data-background-color="#eeeeee">
                <div class="background background--image opacity-5 background--repeat-repeat">
                    <img src="assets/img/pattern-topo.png" alt="">
                </div>
            </div>
            <!--end background-->
        </section>

        <!-- #the-team(for mobile) -->
        <section class="skew-down-left skew-up-right" id="the-team">
            <div class="container  d-block d-md-none d-lg-none">
                <div class="block__wrapper">
                    <!--<div class="block__title">
                        <h2 class="reveal">The Team</h2>
                    </div>-->
                    <!--end block-title-->
                    <h2 class="reveal" style="margin-top: 90px;">The Team </h2>
                    <div class="row justify-content-center">
                        <div class="col-6 col-sm-6 col-md-6 col-lg-4">
                            <div class="person">
                                <h4 class="reveal">Sim Ferns</h4>
                                <h5>Co-Founder<br>(Dream Alchemist)</h5>
                                <div class="person__image">
                                    <a href="https://www.instagram.com/_simrnoff_/" target="_blank">
                                        <div class="person__social">

                                            <i class="fa fa-instagram"></i>

                                        </div>
                                    </a>
                                    <div class="img-into-bg">
                                        <img class="img-fluid" src="assets/img/simferns.png" alt="">
                                    </div>
                                </div>
                                <!--end person__image-->
                            </div>
                            <!--end person-->
                        </div>
                        <!--end col-md-3-->
                        <div class="col-6  col-sm-6 col-md-6 col-lg-4">
                            <div class="person">
                                <h4 class="reveal">Prithvi Patel</h4>
                                <h5>Co-Founder<br>(Chief Enjoyment Officer)</h5>
                                <div class="person__image">
                                    <a href="https://www.instagram.com/prithvi2803/" target="_blank">

                                        <div class="person__social">
                                            <i class="fa fa-instagram"></i>

                                        </div>
                                    </a>
                                    <div class="img-into-bg">
                                        <img class="img-fluid" src="assets/img/prithvi.png " alt="">
                                    </div>
                                </div>
                                <!--end person__image-->
                            </div>
                            <!--end person-->
                        </div>
                        <!--end col-md-3-->

                        <div class="col-6 col-sm-6 col-md-6 col-lg-4">
                            <div class="person">
                                <h4 class="reveal" style="margin-top: 13%;">Sumitava Ghosh</h4>
                                <h5>(Crayon Evangelist)</h5>
                                <div class="person__image">
                                    <a href="https://instagram.com/iamsumitava?igshid=YmMyMTA2M2Y=" target="_blank">

                                        <div class="person__social">
                                            <i class="fa fa-instagram"></i>

                                        </div>
                                    </a>
                                    <div class="img-into-bg"
                                        style="background-image: url('assets/img/Sumitava Ghosh.png')">
                                        <img class="img-fluid" src="assets/img/Sumitava Ghosh.png" alt="">
                                    </div>
                                </div>
                                <!--end person__image-->
                            </div>
                            <!--end person-->
                        </div>
                        <!-- </div> -->
                        <!--end row-->


                        <!--2nd row-->

                        <!-- <div class="row" style="padding-left: 260px;"> -->
                        <div class="col-6 col-sm-6 col-md-6 col-lg-4 ">
                            <div class="person">
                                <h4 class="reveal" style="margin-top:30px ;">Dhruti Dedhia</h4>
                                <h5>(Hyphenated-Specialist) </h5>
                                <div class="person__image">
                                    <a href="https://www.instagram.com/dedhia.words/" target="_blank">

                                        <div class="person__social">
                                            <i class="fa fa-instagram"></i>

                                        </div>
                                    </a>
                                    <div class="img-into-bg">
                                        <img class="img-fluid" src="assets/img/dhruti.png" alt="">
                                    </div>
                                </div>
                                <!--end person__image-->
                            </div>
                            <!--end person-->
                        </div>
                        <div class="col-6 col-sm-6 col-md-6 col-lg-4" style="margin-bottom: 20px;">
                            <div class="person">
                                <h4 class="reveal" style="margin-top:30px ;">Shefali Sehgal</h4>
                                <h5>(Crayon Evangelist) </h5>
                                <div class="person__image">
                                    <a href="https://www.instagram.com/sheffalisehgal99/" target="_blank">

                                        <div class="person__social">
                                            <i class="fa fa-instagram"></i>

                                        </div>
                                    </a>
                                    <div class="img-into-bg">
                                        <img class="img-fluid" src="assets/img/shefali.png" alt="">
                                    </div>
                                </div>
                                <!--end person__image-->
                            </div>
                            <!--end person-->
                        </div>
                    </div>



                </div>
                <!--end block__wrapper-->

            </div>
            <!--end container-->
            <div class="background-wrapper" data-background-color="#eeeeee">
                <div class="background background--image opacity-5 background--repeat-repeat">
                    <img src="assets/img/pattern-topo.png" alt="">
                </div>
            </div>
            <!--end background-->
        </section>

        <!-- #the-team(for ipad) -->
        <section class="skew-down-left skew-up-right" style="margin-top: 80px;" id="the-team">
            <div class="container d-none d-md-block d-lg-none">
                <div class="block__wrapper">
                    <!--<div class="block__title">
                        <h2 class="reveal">The Team</h2>
                    </div>-->
                    <!--end block-title-->
                    <h2 class="reveal" style="margin-top: 80px;">The Team </h2>
                    <div class="row justify-content-center">
                        <div class="col-6 col-sm-6 col-md-6 col-lg-4">
                            <div class="person">
                                <h4 class="reveal">Sim Ferns</h4>
                                <h5>Co-Founder<br>(Dream Alchemist)</h5>
                                <div class="person__image">
                                    <a href="https://www.instagram.com/_simrnoff_/" target="_blank">
                                        <div class="person__social">

                                            <i class="fa fa-instagram"></i>

                                        </div>
                                    </a>
                                    <div class="img-into-bg">
                                        <img class="img-fluid" src="assets/img/simferns.png" alt="">
                                    </div>
                                </div>
                                <!--end person__image-->
                            </div>
                            <!--end person-->
                        </div>
                        <!--end col-md-3-->
                        <div class="col-6  col-sm-6 col-md-6 col-lg-4">
                            <div class="person">
                                <h4 class="reveal">Prithvi Patel</h4>
                                <h5>Co-Founder<br>(Chief Enjoyment Officer)</h5>
                                <div class="person__image">
                                    <a href="https://www.instagram.com/prithvi2803/" target="_blank">

                                        <div class="person__social">
                                            <i class="fa fa-instagram"></i>

                                        </div>
                                    </a>
                                    <div class="img-into-bg">
                                        <img class="img-fluid" src="assets/img/prithvi.png " alt="">
                                    </div>
                                </div>
                                <!--end person__image-->
                            </div>
                            <!--end person-->
                        </div>
                        <!--end col-md-3-->

                        <div class="col-6 col-sm-6 col-md-6 col-lg-4">
                            <div class="person">
                                <h4 class="reveal" style="margin-top: 10%;">Sumitava Ghosh</h4>
                                <h5>(Crayon Evangelist)</h5>
                                <div class="person__image">
                                    <a href="https://instagram.com/iamsumitava?igshid=YmMyMTA2M2Y=" target="_blank">

                                        <div class="person__social">
                                            <i class="fa fa-instagram"></i>

                                        </div>
                                    </a>
                                    <div class="img-into-bg"
                                        style="background-image: url('assets/img/Sumitava Ghosh.png')">
                                        <img class="img-fluid" src="assets/img/Sumitava Ghosh.png" alt="">
                                    </div>
                                </div>
                                <!--end person__image-->
                            </div>
                            <!--end person-->
                        </div>
                        <!-- </div> -->
                        <!--end row-->


                        <!--2nd row-->

                        <!-- <div class="row" style="padding-left: 260px;"> -->
                        <div class="col-6 col-sm-6 col-md-6 col-lg-4 ">
                            <div class="person">
                                <h4 class="reveal" style="margin-top:30px ;">Dhruti Dedhia</h4>
                                <h5>(Hyphenated-Specialist) </h5>
                                <div class="person__image">
                                    <a href="https://www.instagram.com/dedhia.words/" target="_blank">

                                        <div class="person__social">
                                            <i class="fa fa-instagram"></i>

                                        </div>
                                    </a>
                                    <div class="img-into-bg">
                                        <img class="img-fluid" src="assets/img/dhruti.png" alt="">
                                    </div>
                                </div>
                                <!--end person__image-->
                            </div>
                            <!--end person-->
                        </div>
                        <div class="col-6 col-sm-6 col-md-6 col-lg-4" style="margin-bottom: 20px;">
                            <div class="person">
                                <h4 class="reveal" style="margin-top:30px ;">Shefali Sehgal</h4>
                                <h5>(Crayon Evangelist) </h5>
                                <div class="person__image">
                                    <a href="https://www.instagram.com/sheffalisehgal99/" target="_blank">

                                        <div class="person__social">
                                            <i class="fa fa-instagram"></i>

                                        </div>
                                    </a>
                                    <div class="img-into-bg">
                                        <img class="img-fluid" src="assets/img/shefali.png" alt="">
                                    </div>
                                </div>
                                <!--end person__image-->
                            </div>
                            <!--end person-->
                        </div>
                    </div>



                </div>
                <!--end block__wrapper-->

            </div>
            <!--end container-->
            <div class="background-wrapper" data-background-color="#eeeeee">
                <div class="background background--image opacity-5 background--repeat-repeat">
                    <img src="assets/img/pattern-topo.png" alt="">
                </div>
            </div>
            <!--end background-->
        </section>

        <!--end #our-team.block-->
        <!--*********************************************************************************************************-->
        <!--************ FOOTER *************************************************************************************-->
        <!--********************************************************************************************************-->
        <footer id="footer" class="skew-up-right ">
            <div class="container">
                <div class="block__wrapper">
                    <div class="block__title" id="contact">
                        <h2 style="font-size: 3.6rem;" class="h2 mt mgt h2-font"> Coffee & Pretzel Date? </h2>
                    </div>
                    <!--end block-title-->
                    <div class="row">
                        <div class="col-xl-5 col-lg-6 col-md-12 col-sm-12">
                            <div class="row">
                                <div class="col-sm-6 col-lg-12">
                                    <!-- <h4>Our Address</h4>
                              <address>
                                4, Neelkanth Ashish,
                                  <br>
                                  R. B. Mehta Marg,
                                  <br>
                                  Ghatkopar (East),
                                  <br>
                                  Mumbai - 400077.
                              </address> -->
                                </div>
                                <!--end col-sm-6 col-lg-12-->
                                <div class="col-sm-12 col-lg-12">
                                    <div class="py-4">
                                        <figure>

                                            <i class="fa fa-envelope width-20px justify-content-sm-start"></i>
                                            <a href="mailto:theoffice@discoandpretzel.com" target="_blank">
                                                theoffice@discoandpretzel.com</a>
                                        </figure>
                                        <!-- <figure>
                                      <i class="fa fa-facebook width-20px"></i>
                                      <a href="http://facebook.com/teamampersandco"> /teamampersandco</a>
                                  </figure> -->
                                        <figure>
                                            <i class="fa fa-instagram width-20px"></i>
                                            <a href="https://www.instagram.com/discoandpretzel justify-content-sm-start"
                                                target="_blank">
                                                /discoandpretzel</a>
                                        </figure>
                                    </div>
                                    <!--end py-4-->
                                </div>
                                <!--end col-sm-6 col-lg-12-->
                            </div>
                            <!--end row-->
                        </div>
                        <!--end col-xl-5 col-lg-6 col-md-12 col-sm-12-->
                        <div class="col-xl-7 col-lg-6 col-md-12 col-sm-12">
                            <h4>Talk to us</h4>

                            <form [formGroup]="addFrom">
                                <div class="row">
                                    <div class="col-md-6 col-sm-6">
                                        <div class="form-group">
                                            <label for="form-contact-name">Your Name*</label>
                                            <input type="text" class="form-control" formControlName="Name" id="Name"
                                                name="name" placeholder="Your Name" required>
                                            <div *ngIf="addFrom.get('Name').invalid && (addFrom.get('Name').dirty || addFrom.get('Name').touched)"
                                                class="alert">
                                                <div *ngIf="addFrom.get('Name').errors.required">
                                                    Name is required.
                                                </div>
                                            </div>
                                        </div>
                                        <!--end form-group -->
                                    </div>
                                    <!--end col-md-6 col-sm-6 -->
                                    <div class="col-md-6 col-sm-6">
                                        <div class="form-group">
                                            <label for="form-contact-email">Your Email*</label>
                                            <input type="email" class="form-control" formControlName="Email" id="Email"
                                                name="email" placeholder="Your Email" required>
                                            <div *ngIf="addFrom.get('Email').invalid && (addFrom.get('Email').dirty || addFrom.get('Email').touched)"
                                                class="alert">
                                                <div *ngIf="addFrom.get('Email').errors.required">
                                                    Email is required.
                                                </div>
                                                <div *ngIf="addFrom.get('Email').errors.pattern">
                                                    Please include a valid email address
                                                </div>
                                            </div>
                                        </div>
                                        <!--end form-group -->
                                    </div>
                                    <!--end col-md-6 col-sm-6 -->
                                </div>
                                <!--end row -->
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label for="form-contact-message">Your Message*</label>
                                            <textarea class="form-control" id="Message" formControlName="Message"
                                                rows="8" name="message" placeholder="Your Message" required></textarea>
                                            <div *ngIf="addFrom.get('Message').invalid && (addFrom.get('Message').dirty || addFrom.get('Message').touched)"
                                                class="alert">
                                                <div *ngIf="addFrom.get('Message').errors.required">
                                                    Message is required.
                                                </div>
                                            </div>
                                        </div>
                                        <!--end form-group -->
                                    </div>
                                    <!--end col-md-12 -->
                                </div>
                                <!--end row -->
                                <div class="form-group clearfix">
                                    <button type="submit" class="btn pull-right btn-default" id="form-contact-submit"
                                        (click)="addContact()" [disabled]="!addFrom.valid">Send a Message</button>
                                </div>
                                <!--end form-group -->
                                <!-- <div class="form-contact-status"></div> -->
                            </form>
                            <!--end form-contact -->
                        </div>
                        <!--end col-xl-7-->
                    </div>
                    <!--end row-->
                </div>
                <!--end block__wrapper-->
            </div>
            <!--end container-->

            <hr class="hr-white opacity-10">
            <!--end hr-->

            <div class="container">
                <span>(C) Copyright 2021 | Powered by Kenmark ITan Solutions</span>
            </div>
            <!--end container-->

            <div class="background-wrapper" data-background-color="#151a40">
                <div class="background background--particles"></div>
            </div>
            <!--end background-->

        </footer>




        <!--end footer-->
    </div>
    <!--end page-->
</div>