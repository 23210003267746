import { Injectable } from '@angular/core';
import { apiservice } from './apiservice.service';

import { Router } from '@angular/router';
import { SidStorageService } from './sidstorage.service';



@Injectable({
  providedIn: 'root'
})
export class ConstantsService {
  
  constructor(public apiservice: apiservice, public localstorage: SidStorageService,
   
    public router:Router,
    ) { }

  CONTACT = 'contact';

}
