import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';


declare var db: any
@Injectable({
   providedIn: 'root'
})


export class SidStorageService {
   public storagename = "Discopretzeldb";

   getItem(key) {
      return new Promise(async (resolve, reject) => {
         // setTimeout(() => {
            if (db != undefined) {
               const transaction = await db.transaction([this.storagename]);
               const objectStore = await transaction.objectStore(this.storagename);
               var request =  objectStore.get(key);
               request.onsuccess = await function (event) {
                  //   console.log(event)
                  // Do something with the request.result!
                  if (event.target.result != undefined) {
                     //  console.log(request.result)
                     resolve(event.target.result)
                  } else {
                     resolve(false)
                  }
               };
            }
         // }, 500)


      });

   }

   readAll() {
      var objectStore = db.transaction(this.storagename).objectStore(this.storagename);

      objectStore.openCursor().onsuccess = function (event) {
         var cursor = event.target.result;

         if (cursor) {
            alert("Name for id " + cursor.key + " is " + cursor.value.name + ",  Age: " + cursor.value.age + ", Email: " + cursor.value.email);
            cursor.continue();
         } else {
            alert("No more entries!");
         }
      };
   }



   deleteItems(key) {
      return new Promise(async(resolve, reject) => {
         var request = await db.transaction([this.storagename], "readwrite")
            .objectStore(this.storagename).delete(key);

         request.onerror = function (event) {
            reject('not deleted')
         }

         request.onsuccess = function (event) {
            resolve('delete successfully')
         };
      });

   }

   async updateItem(keyname, value) {
     return new Promise (async (resolve,reject ) => {
         if (db != undefined) {
            var request = await db.transaction([this.storagename], "readwrite")
               .objectStore(this.storagename).put(value, keyname);
         
         
               request.onsuccess = await function (event) {
                  if (event.target.result != undefined) {
                     //  console.log(request.result)
                     resolve(event.target.result)
                  } else {
                     resolve(false)
                  }
                 
               };
            }
         

      });

   }

}